import { UUID } from 'crypto'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { supabase } from '../../utils/supabase'
import { UserInterface } from '../../types/user'
import { useSnackbar } from '../../hooks/useSnackbar'

interface InviteInterface {
  id: UUID,
  chatroomId: UUID
}


const InviteCard = (props: InviteInterface) => {
  const [userData, setUserData] = useState<UserInterface>()
  const snackbar = useSnackbar()
  const mainRef = useRef<HTMLDivElement>(null)

  // NOTE: this checks if the invite is already accepted
  useEffect(() => {

    async function checkIfChatExists() {
      try {
        const { data: sessionData, error: sessionError } = await supabase.auth.getSession()
        if (sessionError) {
          throw sessionError
        }
        const { data, error } = await supabase.from("chatrooms").select().eq('id', props.chatroomId).single();

        const { data: checkChatExistsDataOne } = await supabase.from("chatrooms").select().eq("accepted", true)
          .eq("inviting_user", data!.inviting_user!).eq("invited_user", sessionData!.session!.user.id).maybeSingle()
        const { data: checkChatExistsDataTwo } = await supabase.from("chatrooms").select().eq("accepted", true)
          .eq("inviting_user", sessionData!.session!.user.id).eq("invited_user", data!.inviting_user!).maybeSingle()
        if (checkChatExistsDataOne || checkChatExistsDataTwo) {
          mainRef.current?.remove()
        }
      } catch (error) {
        console.log(error)
      }
    }
    checkIfChatExists()
  }, [])

  useEffect(() => {
    async function getUserData() {
      try {
        const { data, error } = await supabase.from("profiles").select().eq("id", props.id).single()
        if (error) {
          throw error
        }
        if (data === null) {
          throw new Error("User Data should not be null")
        }
        setUserData(data as UserInterface)
      } catch (error) {
        console.log(error)
      }

    }
    getUserData()
  }, [])

  async function handleAccept() {
    try {
      const { error: UpsertError } = await supabase.from("chatrooms")
        .update({ accepted: true })
        .eq("id", props.chatroomId)
      if (UpsertError) {
        throw UpsertError
      }
      mainRef.current?.remove()
    } catch (error) {
      console.log(error)
    }
  }
  async function handleDecline() {
    try {
      await supabase.from("chatrooms").delete().eq("id", props.chatroomId).throwOnError()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div ref={mainRef} className='flex flex-col sm:flex-col sm:gap-3 justify-between gap-4 p-8 rounded-2xl
             items-center md:w-full w-[1000px] shadow-xl bg-base-200 h-full sm:p-4'>
      <div className='w-full flex flex-col items-center gap-2'>
        <div className='flex flex-row gap-4 sm:gap-0 items-center w-full h-[5.5rem] sm:h-full justify-between sm:justify-evenly'>
          <img src={userData?.pfp_link} className="h-20 sm:h-16 rounded-full" />
          <div className="flex flex-col justify-evenly">
            <h1 className=' text-xl sm:text-lg font-[600]'>{userData?.name} invited you to a chat!</h1>
            <p className='block w-[90%] sm:hidden break-words'>
              {userData?.bio}
            </p>
          </div>
        </div>
        <p className='hidden w-[90%] sm:block break-words'>{userData?.bio}</p>
      </div>
      <div className='flex flex-row w-full gap-4 sm:flex-col'>
        <button className='sm:w-full w-[50%] btn btn-success' onClick={() => handleAccept()}>
          Accept
        </button>
        <button className='sm:w-full w-[50%] btn btn-error' onClick={() => handleDecline()}>
          Decline
        </button>
      </div>
    </div>
  )


}

export default InviteCard
