import React, { useEffect } from 'react'
import { supabase } from '../../utils/supabase'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Auth } from '@supabase/auth-ui-react';
import {
  ThemeSupa,
} from '@supabase/auth-ui-shared'
import './SignUp.css'

const Signup = () => {
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    // DEV: delete when push to main
    async function devBranchLogin() {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: 'tester@ringodating.com',
        password: 'j4jP6ENL',
      })
      console.log(data)
      console.log(error)
      navigate("/dashboard");

    }
    // devBranchLogin()
    async function getUser() {
      try {
        const { data, error } = await supabase.auth.getSession()
        console.log(params)
        if (data.session !== null) {
          if (params.signOut === null || params.signOut !== "signOut") {
            navigate("/dashboard");
          }
        }
        if (error) {
          throw error
        }
      } catch (e) {
        console.log(e)
      }
    }
    getUser()
  }, [])

  return (
    <html>
      <head>
        <title>Ringo | Text Based Dating App</title>
      </head>
      <div className='flex justify-between flex-row min-h-[100dvh] w-[100dvw] pl-36 md:pl-0 bg-image bg-cover'>
        <div className="flex items-center justify-center mt-auto mb-auto">
          <div className=" py-6 hero-content rounded-3xl lg:gap-0 max-w-[60rem] flex-col items-center md:glassmorphism text-slate-900 md:text-slate-100">
            <div className="text-center lg:text-left w-full pt-0 lg:pt-10 lg:w-[90%]">
              <h1 className="text-6xl lg:text-4xl title text-center">Ringo - Text Based Dating App</h1>
              <p className="py-6 text-center lg:text-md font-[600]">
                Ringo is a minimalist text-based dating app where you can connect with like-minded people effortlessly. No profile pictures, just meaningful conversations and authentic connections.
              </p>
            </div>
            <div className="card shrink-0 lg:w-full h-full w-full md:glassmorphism-darkest glassmorphism-darker ">
              <div className="form-control w-full">
                <div className='card  px-4 py-16 w-full h-full flex flex-col items-center text-cen text-xl gap-4'>
                  <h1 className='font-[700] flex flex-col items-center gap-6 text-4xl text-success text-center w-full'>Sign in with Google
                    <span className='font-[400] px-8 text-sm text-center leading-loose opacity-70 text-base-content'>
                      *More sign in methods will be added upon popular request.
                    </span>
                  </h1>
                  <Auth
                    supabaseClient={supabase}
                    appearance={{ theme: ThemeSupa }}
                    theme="dark"
                    onlyThirdPartyProviders
                    providers={["google"]}

                  />
                  <Link to="/FAQ" className=' bg-blue-700 px-5 py-4 rounded-md border border-slate-500 text-slate-300 font-[600] text-sm'>FAQ & Privacy Policy</Link>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className='w-[100%] md:hidden z-[100] bg-cover'>

        </div>
      </div>
    </html>
  )
}

export default Signup
