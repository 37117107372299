import { OnApproveBraintreeData, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useEffect } from "react";
import { supabase } from "../../utils/supabase";

const ButtonWrapper = ({ type }) => {
  const [{ options }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        intent: "subscription",
      },
    });
  }, [type]);

  return (
    <div className="flex items-center flex-col justify-center bg-base-200 max-w-96 xs:max-w-80 w-96 sm:w-full rounded-3xl p-8 self-center gap-4">
      <h2 className=' font-[600] text-lg'>This is just for testing</h2>
      <PayPalButtons
        createSubscription={(data, actions) => {
          return actions.subscription
            .create({
              plan_id: "P-15A186713V644894PMX3LFHI",
            })
            .then((orderId) => {
              // Your code here after create the order
              return orderId;
            });
        }}
        style={{
          label: "subscribe",
        }}
        onApprove={onApprove}
      />
    </div>
  );
}

export default ButtonWrapper

async function onApprove(data: OnApproveBraintreeData) {
  const user = await supabase.auth.getUser()
  console.log(user.data.user?.id)
  supabase.functions.invoke('payment-success', {
    body: {
      orderID: data.orderID,
      userID: user.data.user?.id
    },
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  }).then((res) => {
    console.log(res)
  })

}
