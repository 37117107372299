import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { supabase } from '../../utils/supabase';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MessageInterface } from '../../types/message';
import { UUID } from 'crypto';
import { z } from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


import dayjs from 'dayjs';
import { Tables } from '../../types/supabase';
var customParseFormat = require('dayjs/plugin/customParseFormat')
var isToday = require('dayjs/plugin/isToday')
var isYesterday = require('dayjs/plugin/isYesterday')

dayjs.extend(isYesterday)
dayjs.extend(customParseFormat)
dayjs.extend(isToday)

const chatSchema = z.object({
    chat: z.string().min(1)
})

type chatSchemaType = z.infer<typeof chatSchema>;

interface CurrentUserChatInterface extends Tables<"messages"> {
    isLastElement: boolean
}

const CurrentUserChat = (props: CurrentUserChatInterface) => {
    function lastElementFooter() {
        if (props.isLastElement) {
            if (props.read) {
                {/* @ts-ignore cant find isToday */ }
                if (dayjs(props.read_at).isToday()) {
                    return (`seen at ${dayjs(props.read_at).format('HH:mm')}`)
                } else {
                    return (`seen at ${dayjs(props.read_at).format('MMM DD HH:mm')}`)
                }
            } else {
                return ("Delivered")
            }
        } else {
            return (<></>)
        }
    }
    return (
        <div className=' chat chat-end'>
            <div className='chat-bubble'>{props.content}</div>
            <div className="chat-footer opacity-50">
                {lastElementFooter()}
            </div>
        </div>
    )
}

const OppositeUserChat = (message: Tables<"messages">) => {
    return (
        <div className=' chat chat-start'>
            <div className='chat-bubble dark:text-white chat-bubble-primary'>{message.content}</div>
            <div className="chat-footer opacity-50">
                {/* @ts-ignore cant find isToday */}
                {dayjs(message.created_at).isToday() ? (dayjs(message.created_at).format('HH:mm')) : (
                    dayjs(message.created_at).format('MMM DD HH:mm')
                )}
            </div>
        </div>
    )
}



const Chat = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [messages, setMessages] = useState<Array<Tables<"messages">>>([]);
    const [userId, setUserId] = useState<UUID>()
    const [oppositeUserData, setOppositeUserData] = useState<Tables<"profiles">>()
    const divRef = useRef<HTMLDivElement>(null)
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<chatSchemaType>({ resolver: zodResolver(chatSchema) });
    async function handleLeaveChat() {
        try {
            const { error } = await supabase.from("chatrooms").delete().eq("id", params.id!)
            if (error) {
                throw error
            }
            navigate("/dashboard")
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        divRef!.current!.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest'
        })
    }, [messages])
    useEffect(() => {

        async function getCurrentUserAndUpdateReadStatus() {

            const { data, error } = await supabase.auth.getSession()
            if (data.session === null) {
                navigate("/complete-profile")
            }
            if (error) {
                console.log(error)
            }
            setUserId(data!.session!.user.id as UUID)

            try {

                const { error: updateReadError } = await supabase.from("messages")
                    .update({ read: true, read_at: (new Date().toISOString()) }).eq("chatroom_id", params.id!).neq("user_id", data!.session!.user.id)
                if (updateReadError) {
                    throw updateReadError
                }
            } catch (error) {
                console.log(error)
            }
        }
        getCurrentUserAndUpdateReadStatus()

    }, [])
    function handleMessageUpdate() {
        async function getMessages() {
            const { data: messagesData, error: messagesError } = await supabase.from("messages").select().eq("chatroom_id", params.id!).order('created_at', { ascending: false });
            if (messagesError) {
                console.log(messagesError)
            }
            setMessages(messagesData!)

        }
        getMessages()

    }

    useEffect(() => {
        async function getMessages() {
            const { data: messagesData, error: messagesError } = await supabase.from("messages").select().eq("chatroom_id", params.id!).order('created_at', { ascending: false });
            if (messagesError) {
                console.log(messagesError)
            }
            setMessages(messagesData!)
        }
        getMessages()
        try {
            supabase
                .channel('messages')
                .on('postgres_changes', {
                    event: 'INSERT', schema: 'public', table: 'messages', filter: `chatroom_id=eq.${params.id}`
                }, handleMessageUpdate).subscribe()
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        async function getChatroomData() {
            try {
                const { data: sessionData, error: sessionError } = await supabase.auth.getSession()
                const { data: chatroomData, error: chatroomError } = await supabase.from("chatrooms").select().eq('id', params.id!).single()
                if (chatroomError) {
                    throw chatroomError
                }
                if (chatroomData.invited_user !== sessionData.session?.user.id && chatroomData.inviting_user !== sessionData.session?.user.id) {
                    throw new Error("Unauthorized")
                }
                if (chatroomData?.inviting_user === userId) {
                    const { data: oppositeUserData, error: oppositeUserError } = await supabase.from("profiles").select().eq('id', chatroomData?.invited_user!).single()
                    setOppositeUserData(oppositeUserData!)
                    if (oppositeUserError) {
                        throw oppositeUserError
                    }
                } else if (chatroomData?.invited_user === userId) {
                    const { data: oppositeUserData, error: oppositeUserError } = await supabase.from("profiles").select().eq('id', chatroomData?.inviting_user!).single()
                    setOppositeUserData(oppositeUserData!)
                    if (oppositeUserError) {
                        throw oppositeUserError
                    }
                }
            } catch (error) {
                // TODO: all this any maybe others should send to error page
                console.log(error)
                navigate("/error")
            }
        }
        getChatroomData()
    }, [userId])


    const onSubmit: SubmitHandler<chatSchemaType> = async (submittedData, e) => {
        e?.preventDefault()
        try {
            const { data: UserData, error: UserError } = await supabase.auth.getSession()
            const { error: updateChatroomsError } = await supabase.from("chatrooms").update({ latest_message_at: new Date().toISOString() }).eq('id', params.id!)
            if (updateChatroomsError) {
                throw updateChatroomsError
            }
            const { data, error } = await supabase.from("messages").insert({ content: submittedData.chat, chatroom_id: params.id, user_id: UserData.session?.user.id }).select()
            console.log(error)
            setMessages((prev) => [...prev, data! as unknown as MessageInterface])
        } catch (error) {
            console.log(error)
        } finally {
            reset()
            e?.target.reset()
        }
    }
    return (
        <>
            <div className='w-full flex flex-col'>
                <div className=' pt-4 z-[100] bg-base-100 py-4 text-lg w-full flex flex-row justify-around items-center'>
                    <Link to="/"><ArrowBackIcon fontSize='large' /> <span>Back to Dashboard</span></Link>
                    <div className="dropdown dropdown-end">
                        <div tabIndex={0} role="button" className="text-[4rem] h-16">{oppositeUserData?.pfp_link !== null ?
                            (<img src={oppositeUserData?.pfp_link} className="rounded-full h-16 w-16" />) : (<AccountCircleIcon fontSize='inherit' />)
                        }</div>

                        <div tabIndex={0} className=" gap-4 dropdown-content z-[1] border-success border-[3px] menu p-2 pt-3 shadow bg-base-100 rounded-box w-72">
                            <div className='flex gap-2 flex-col items-center'>
                                <span className='text-xl font-[600]'>{oppositeUserData?.name}</span>
                                <span>{oppositeUserData?.bio}</span>
                            </div>
                            <button onClick={() => handleLeaveChat()}
                                className='btn-error bg-error btn text-black rounded-md items-center text-center'>Leave Chat</button>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col-reverse mt-auto last:mb-[3rem] w-[60%] self-center sm:w-full' >

                    {messages.map((message, i) => {
                        if (message.user_id === userId) {
                            return (<CurrentUserChat key={message.created_at} {...message} isLastElement={i === 0} />)
                        } else {
                            return (<OppositeUserChat {...message} key={message.created_at} />)

                        }
                    })}
                </div>
            </div>
            <form className='self-center flex flex-col items-center mt-10 w-full mb-4'
                onSubmit={handleSubmit(onSubmit)}>
                <div className='join bottom-5 fixed h-16 w-full justify-center'>
                    <input className='input input-bordered join-item w-[55%] h-16 sm:w-full' placeholder='Say something funny!' type='text' {...register("chat")} />
                    <button className='btn join-item rounded-r-full h-16 p-5 pr-6' type='submit'>Enter</button>
                </div>

            </form>
            <div ref={divRef} />
        </>
    )
}

export default Chat