'use client'

import React, {
	createContext,
	useContext,
	useState,
	useCallback,
	ReactNode
} from 'react'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

// Define the context and its interface
interface SnackbarContextProps {
	showMessage: (
		message: string,
		severity?: AlertProps['severity'],
		icon?: boolean,
		position?: SnackbarOrigin,


	) => void
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(
	undefined
)

// SnackbarProvider component
interface SnackbarProviderProps {
	children: ReactNode
}

function snackbarTextColor(severity: AlertProps['severity']) {
	switch (severity) {
		case 'error':
			return '#BA1A1A';
		case 'success':
			return '#006C45';
		case 'info':
			return ' #0039B4';
		default:
			return;
	}
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
	children
}) => {
	const [open, setOpen] = useState(false)
	const [snackbarConfig, setSnackbarConfig] = useState<{
		message: string
		severity?: AlertProps['severity'],
		icon?: boolean,
		position: SnackbarOrigin

	}>({
		message: '',
		severity: undefined,
		icon: true,
		position: { vertical: 'bottom', horizontal: 'left' } // Default position
	})

	const showMessage = useCallback(
		(
			message: string,
			severity?: AlertProps['severity'],
			icon: boolean = true,
			position: SnackbarOrigin = { vertical: 'top', horizontal: 'center' }

		) => {
			setSnackbarConfig({ message, severity, position, icon })
			setOpen(true)
		},
		[]
	)

	const handleClose = (
		event?: React.SyntheticEvent<any, Event>,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return
		}
		setOpen(false)
	}
	return (
		<SnackbarContext.Provider value={{ showMessage }}>
			{children}
			<Snackbar
				open={open}
				autoHideDuration={6000}
				// @ts-ignore
				onClose={handleClose}
				anchorOrigin={snackbarConfig.position}

			>
				<MuiAlert
					severity={snackbarConfig.severity}
					onClose={handleClose}
					sx={{ width: '100%', color: snackbarTextColor(snackbarConfig.severity), backgroundColor: 'white' }}
					elevation={6}
					icon={snackbarConfig.icon === true ? undefined : false}
					variant='filled'
				>
					{snackbarConfig.message}
				</MuiAlert>
			</Snackbar>
		</SnackbarContext.Provider>
	)
}

// Custom hook
export const useSnackbar = () => {
	const context = useContext(SnackbarContext)
	if (!context) {
		throw new Error('useSnackbar must be used within a SnackbarProvider')
	}

	const { showMessage } = context

	const showErrorMessage = (
		msg: string | Error | unknown,
		icon?: boolean,
		opts?: SnackbarOrigin
	) => {
		console.error(msg)

		if (typeof msg === 'string') {
			return showMessage(msg ?? 'Unexpected error occured', 'error', icon, opts)
			//@ts-ignore
		} else if (msg?.message) {
			return showMessage(JSON.stringify(msg), 'error', icon, opts)
		} else {
			showMessage(JSON.stringify(msg), 'error', icon, opts)
		}
	}

	const showInfoMessage = (msg: string, icon?: boolean, opts?: SnackbarOrigin) => {
		showMessage(msg, 'info', icon, opts)
	}

	const showSuccessMessage = (msg: string, icon?: boolean, opts?: SnackbarOrigin) => {
		showMessage(msg, 'success', icon, opts)
	}

	return { showMessage, showErrorMessage, showInfoMessage, showSuccessMessage }
}

export default SnackbarProvider
