import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SnackbarProvider from '../hooks/useSnackbar'
import LogoSvg from './svgs/LogoSvg'
const Layout = ({ children }) => {
    const navigate = useNavigate();
    return (
        <SnackbarProvider>

            <div className="min-h-[100dvh] flex flex-col items-center ">
                {children}


            </div>
        </SnackbarProvider>

    )
}

export default Layout