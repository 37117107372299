import React, { useEffect, useRef, useState } from 'react'
import ManSvg from '../../components/svgs/ManSvg'
import { supabase } from '../../utils/supabase'
import WomanSvg from '../../components/svgs/WomanSvg'
import { UserInterface } from '../../types/user'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { getHour, msToTime } from '../../utils/utils'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

enum ReportCategoryEnum {
  NameOrBio = "NameOrBio",
  Bullying = "Bullying",
  Pfp = "Pfp"
}

const ReportSchema = z.object({
  reportType: z.nativeEnum(ReportCategoryEnum),
  reason: z.string().min(15)
});

type ReportSchemaType = z.infer<typeof ReportSchema>;

const UserCard = (props: UserInterface) => {
  const [reportSelected, setReportSelected] = useState(false)
  const [reportSubmitted, setReportSubmitted] = useState(false)
  const [dailyInviteReached, setDailyInviteReached] = useState(false)
  const [inviteSent, setInviteSent] = useState(false)
  const [timeLeft, setTimeLeft] = useState<string>('')
  const mainRef = useRef<HTMLDivElement>(null)

  async function handleInvite() {
    try {
      const currentDate = new Date()
      const { data: UserData, error: UserError } = await supabase.auth.getSession()
      if (UserError) {
        throw UserError
      }
      // fetch from profile table
      const { data: UserProfile, error: ProfileError } = await supabase.from("profiles").select().eq("id", UserData!.session!.user.id).single()
      // check if its been 24 hours since last countdown
      if (ProfileError) {
        throw ProfileError
      }
      function inviteTimePassed() {
        if (UserProfile!.daily_invite_start === null) {
          return true
        } else if (Date.parse(UserProfile!.daily_invite_start) + 24 * 60 * 60 * 1000 < currentDate.getTime()) {
          return true
        } else {
          return false
        }
      }
      // for premium
      if (UserProfile.premium === true) {
        // start the chatroom
        const { error } = await supabase.from("chatrooms").insert({ invited_user: props.id, inviting_user: UserData.session?.user.id })
        // set 24 hour countdown to reset daily invite limit
        if (inviteTimePassed()) {
          const { error } = await supabase.from("profiles").upsert({ daily_invite_start: currentDate.toISOString() })
          if (error) {
            throw error
          }
        }
        // increment the daily_invites amount
        const { error: updateDailyError } = await supabase.from("profiles").upsert({ daily_invites: UserProfile.daily_invites + 1 })
        if (updateDailyError) {
          throw updateDailyError
        }
        if (error) {
          throw error
        }
        setInviteSent(true)
      }
      // check if daily limit is reached and user does not have a premium account 
      else if (UserProfile.daily_invites <= 30 || inviteTimePassed() === true) {
        // start the chatroom
        const { error } = await supabase.from("chatrooms").insert({ invited_user: props.id, inviting_user: UserData.session?.user.id })
        // set 24 hour countdown to reset daily invite limit
        if (inviteTimePassed()) {
          const { error } = await supabase.from("profiles").upsert({ daily_invite_start: currentDate.toISOString() })
          if (error) {
            throw error
          }
        }
        // increment the daily_invites amount
        const { error: updateDailyError } = await supabase.from("profiles").upsert({ daily_invites: UserProfile.daily_invites + 1 })
        if (updateDailyError) {
          throw updateDailyError
        }
        if (error) {
          throw error
        }
        setInviteSent(true)
      } else {
        const plusOneDay = Date.parse(UserProfile!.daily_invite_start!) + 24 * 60 * 60 * 1000
        setTimeLeft(msToTime(plusOneDay - currentDate.getTime()))
        setDailyInviteReached(true)
      }

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    async function checkIfInvited() {
      try {
        const { data: UserData, error: UserError } = await supabase.auth.getSession()
        if (UserError) {
          throw UserError
        }
        const { data: beingInvitedDataOne, error } = await supabase.from("chatrooms").select().eq('inviting_user', UserData!.session!.user.id).eq('invited_user', props.id).maybeSingle()

        const { data: beingInvitedDataTwo } = await supabase.from("chatrooms").select()
          .eq('invited_user', UserData!.session!.user.id).eq('inviting_user', props.id).maybeSingle()
        if (beingInvitedDataOne || beingInvitedDataTwo) {
          // TODO: right now it appears for a second then removes, looks shit, need to remove on supabase or in the dashboard
          // mainRef.current?.remove()
        }
      } catch (error) {
        console.log(error)
      }

    }
    checkIfInvited()
  }, [])

  useEffect(() => {
    if (dailyInviteReached) {
      // @ts-ignore Daisy ui
      document.getElementById("daily_limit_dialog").showModal();
    }


  }, [timeLeft])


  function genderStyles() {
    if (props.gender === 'Male') {
      return { color: 'bg-primary', element: <ManSvg /> }
    } else if (props.gender === 'Female') {
      return { color: 'bg-pink-600', element: <WomanSvg /> }
    } else {
      return { color: 'bg-slate-600', element: <></> }
    }
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ReportSchemaType>({ resolver: zodResolver(ReportSchema) });
  const onSubmit: SubmitHandler<ReportSchemaType> = async (submittedData) => {
    const { error: reportError } = await supabase.from("reports")
      .insert({ reported_user_id: props.id, reason: submittedData.reason, category: submittedData.reportType })
    if (reportError) {
      console.log(reportError)
    }
    setReportSubmitted(true)
    reset()
  }
  if (reportSubmitted) {
    // @ts-ignore daisy ui
    document.getElementById('my_modal_7').checked = false
    // @ts-ignore daisyui
    document.getElementById("my_modal_2").showModal();
    document.body.style.overflow = 'auto';

  }
  if (JSON.stringify(errors).length > 2) {
    console.log(errors)
  }
  return (
    <div className={`card w-[25rem] sm:w-[20rem] xs:w-[16rem] h-64 max-h-full text-primary-content ${genderStyles().color}`} ref={mainRef}>
      <div className="card-body p-4 justify-between">
        <div className='flex flex-row justify-between items-center'>
          <div className='flex flex-row gap-5 items-center text-[4rem]'>
            {props.pfp_link ? (<div className="avatar ">
              <div className=" w-16 rounded-full">

                <img src={props.pfp_link} />


              </div>
            </div>) : (<AccountCircleIcon fontSize='inherit' />
            )}

            <h2 className="card-title mt-1">
              {props.name}
            </h2>
          </div>
          <div className="dropdown dropdown-end">
            <div tabIndex={0} role="button"><MoreVertIcon /></div>
            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-200 text-base-content rounded-box w-52">
              <li>
                <label htmlFor="my_modal_7">
                  Report User
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div className='flex flex-col justify-between h-full'>
          <p className='text-left break-words text-wrap justify-self-start'>{props.bio}</p>
          <p className=' justify-self-end max-h-[28px]
           text-white bg-success p-2 rounded-l-xl rounded-r-xl text-sm flex items-center self-start
            '>
            {
              getHour(Date.now() - Date.parse(props?.last_online_at)) === 0 ? (<div className='flex flex-row gap-1 items-center'>
                <span className='h-2 rounded-full bg-white w-2'></span>Online Now</div>) :
                (`last online: ${getHour(Date.now() - Date.parse(props?.last_online_at))} hours ago
                 `)
            }
          </p>

        </div>

        <div className="card-actions flex-nowrap flex-row justify-between flex items-center">
          <div className='flex flex-row gap-2 text-base font-medium'>
            {
              genderStyles().element
            }
            <span>{props.age}</span>
            <span className='text-center'>{props.country}</span>
          </div>

          <button className="btn" onClick={() => handleInvite()} disabled={inviteSent}>{inviteSent ? "Invite Sent" : "Invite To Chat"}</button>
        </div>
      </div>
      <input type="checkbox" id="my_modal_7" className="modal-toggle" onClick={() => {
        // @ts-ignore daisy ui
        if (document.getElementById('my_modal_7').checked) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
      }} />
      <div className="modal" role="dialog">
        <div className="modal-box  items-center self-start flex flex-col text-base-content text-center">
          <h3 className="font-[600] text-lg text-error">Report User</h3>
          <p className="py-4">Select the category of this report and necessary details.
            <span className='text-warning'> IMPORTANT : False reports may result in a ban!</span></p>
          <form className='w-full gap-6 flex flex-col items-center' onSubmit={handleSubmit(onSubmit)}>
            <select className="select select-bordered w-full max-w-xs" defaultValue="Category"
              {...register("reportType")}

              onChange={() => {
                setReportSelected(true)
              }}
            >
              <option disabled value="Category">Category</option>
              <option value="NameOrBio">Inappropriate Name/ Bio</option>
              <option value="Bullying">Harassment/ Bullying</option>
              <option value="Pfp">Inappropriate Profile Picture</option>
            </select>
            {
              reportSelected ? (<div className='w-full max-w-xs'>
                <h2>Reason</h2>
                <textarea {...register("reason")} className="textarea textarea-bordered resize-none w-full h-36" placeholder="Reason for this report"></textarea>
              </div>) : (<></>)
            }
            <button type='submit'>submit</button>
          </form>
        </div>
        <label className="modal-backdrop h-full" htmlFor="my_modal_7"></label>
      </div>
      <dialog id="my_modal_2" className="modal text-base-content w-full" >
        <div className="modal-box items-center flex flex-col text-7xl">
          <PublishedWithChangesIcon className='' fontSize='inherit' />
          <p className="py-4 text-xl">Thanks for your report.</p>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>


      <dialog id="daily_limit_dialog" className="modal text-base-content w-full">
        <div className="modal-box items-center flex flex-col text-2xl text-center">
          You have reached the current maximum daily invites for users.
          <p>Time left: {timeLeft}</p>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </div>
  )
}

export default UserCard
