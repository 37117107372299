import { UUID } from 'crypto'
import React, { useEffect, useRef, useState } from 'react'
import { set } from 'react-hook-form'
import { useSnackbar } from '../../hooks/useSnackbar'
import { Tables } from '../../types/supabase'
import { UserInterface } from '../../types/user'
import { supabase } from '../../utils/supabase'
import { msToSec } from '../../utils/utils'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


interface RealTimeInviteCardInterface {
  inviting_user: UUID
  id: UUID
}



const RealtimeInviteCard = (props: RealTimeInviteCardInterface) => {
  const [userData, setUserData] = useState<Tables<"profiles">>()
  const [currentUserData, setCurrentUserData] = useState<any>()
  const [timeLeftToAccept, setTimeLeftToAccept] = useState<number>()
  const timeLeftInsecsRef = useRef<number>()
  const [inviteData, setInviteData] = useState<any>()
  const [timeMaxed, setTimeMaxed] = useState(false)
  const snackbar = useSnackbar()
  const intervalRef = useRef<any>()
  const mainRef = useRef<HTMLDivElement>(null)
  async function handleAccept() {
    try {
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession()
      if (sessionError) {
        throw sessionError
      }
      const { data: profileData, error: profileError } = await supabase.from("profiles").select().eq("id", sessionData!.session!.user.id).single()
      if (profileError) {
        throw profileError
      }
      const { data: checkChatExistsDataOne } = await supabase.from("chatrooms").select().eq("inviting_user", props.inviting_user).eq("invited_user", sessionData!.session!.user.id).single()
      const { data: checkChatExistsDataTwo } = await supabase.from("chatrooms").select().eq("inviting_user", sessionData!.session!.user.id).eq("invited_user", props.inviting_user).single()
      if (checkChatExistsDataOne || checkChatExistsDataTwo) {
        snackbar.showErrorMessage("you already have a chat with this user.")
        throw new Error("you already have a chat with this user.")
      }
      if (timeMaxed === true) {
        if (profileData.premium === true) {
          const { error: realtimeError } = await supabase.from("realtime_invites").update({ accepted: true }).eq("id", props.id)
          if (realtimeError) {
            throw realtimeError
          }
          const { data, error } = await supabase.from("chatrooms").insert({ inviting_user: props.inviting_user, invited_user: sessionData!.session!.user.id, accepted: false }).select().single()
          // realtime channel in dashboard cant have two filter, will need to trigger the update event
          const { error: updateError } = await supabase.from("chatrooms").update({ accepted: true }).eq("id", data!.id)
          if (updateError) {
            console.log(updateError)
          }
          if (error) {
            throw error
          }
        } else {
          // TODO: implement premium
          snackbar.showInfoMessage('You can upgrade to premium in the profiles tab.')
          throw new Error('this user is not premium')
        }
      } else {
        const { error: realtimeError } = await supabase.from("realtime_invites").update({ accepted: true }).eq("id", props.id)
        if (realtimeError) {
          throw realtimeError
        }
        const { data, error } = await supabase.from("chatrooms").insert({ inviting_user: props.inviting_user, invited_user: sessionData!.session!.user.id, accepted: false }).select().single()
        const { error: updateError } = await supabase.from("chatrooms").update({ accepted: true }).eq("id", data!.id)
        if (updateError) {
          console.log(updateError)
        }
        if (error) {
          throw error
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  async function handleDecline() {
    try {
      const { error } = await supabase.from("realtime_invites").update({ declined: true }).eq("id", props.id)
      if (error) {
        throw error
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    async function getUserData() {
      const { data, error } = await supabase.from("profiles").select().eq('id', props.inviting_user).single()
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession()
      const { data: currentUserData, error: currentUserError } = await supabase.from("profiles").select().eq("id", sessionData!.session!.user.id).single()
      setCurrentUserData(currentUserData)
      setUserData(data!)
      console.log(error)
    }
    getUserData()

  }, [])
  useEffect(() => {
    async function getrealtimeInviteData() {
      try {
        const { data, error } = await supabase.from("realtime_invites").select()
          .eq('id', props.id)
          .maybeSingle()
        setInviteData(data)
        intervalRef.current = setInterval(() => {
          if (data !== null) {
            setTimeLeftToAccept(Date.parse(data!.expire_time!) - Date.now())
          } else {
            clearInterval(intervalRef.current)
          }
        }, 1000)
        if (error) {
          throw error
        }
      } catch (error) {
        console.log(error)
      }
    }
    getrealtimeInviteData()
  }, [])

  useEffect(() => {
    function updateTimeLeft() {
      if (timeLeftToAccept !== undefined) {
        timeLeftInsecsRef.current = msToSec(timeLeftToAccept)
        if (timeLeftInsecsRef.current <= 0) {
          clearInterval(intervalRef.current)
          setTimeMaxed(true)
        }
      }
    }
    updateTimeLeft()
  }, [timeLeftToAccept])
  return (
    <div ref={mainRef} className=' bg-base-200 shadow-2xl w-[1000px] card sm:w-[22rem]'>
      <div className='flex flex-col justify-between gap-4 card-body w-full border-[3px] border-success rounded-2xl'>
        <div className='flex flex-col w-full gap-4 '>
          <div className='flex flex-row justify-between card-title px-0 items-center '>
            {userData?.pfp_link ? (<img src={userData?.pfp_link} className="w-20 sm:w-16 h-20 sm:h-16 rounded-full" />
            ) : (<div className='text-[4rem]'>
              <AccountCircleIcon fontSize='inherit' />
            </div>)}
            <p className='font-[800]'>{userData?.name}</p>
            <h1 className='font-[800] text-success'>Realtime Invite</h1>
          </div>
          <p>{userData?.bio}</p>
        </div>
        <div className='flex flex-row sm:flex-col gap-4 w-full'>
          <button className='btn text-break w-[50%] sm:w-full btn-success' onClick={() => handleAccept()}>
            {
              currentUserData?.premium === true ? `accept` : timeMaxed === false && timeLeftInsecsRef.current ? `you have ${timeLeftInsecsRef.current} seconds to accept` : "upgrade to premium to accept past invites"
            }

          </button>
          <button className='btn btn-error w-[50%] sm:w-full' onClick={() => handleDecline()}>
            Decine
          </button>
        </div>
      </div>
    </div>
  )

}

export default RealtimeInviteCard