import React from 'react'
import './FAQ.css'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const FAQ = () => {
  return (
    <>
      <Link to="/"><ArrowBackIcon fontSize='large' /> <span>Back to Dashboard</span></Link>
      <div className='flex flex-col text-slate-300 font-[600] bg'>
        <div className='px-12 flex flex-col bg-gray-700 rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30'>
          <div className='flex flex-col self-center gap-4 py-8 '>
            <h1 className=' text-5xl self-center'>FAQ</h1>
            <div className='flex flex-col'>
              <h2>Do I need to submit a Profile Pic to use this site?</h2>
              <p>No, but there is an option to do so!</p>
            </div>
            <div className='flex flex-col'>
              <h2>Are there weirdos on this site?</h2>
              <p>Sometimes! Fortunately our report function works really well,
                be sure to report them when u see them!
              </p>
            </div>
            <div className='flex flex-col'>
              <h2>Where can I submit suggestions for the site?</h2>
              <p> Leave us a message at ringodating@protonmail.com!
              </p>
            </div>
            <div className='flex flex-col'>
              <h2>Are you hiring?</h2>
              <p>We are always looking for new talent, if you are self-motivated and enjoy building new projects,
                send us a message at ringodating@protonmail.com! Currently looking for developers(React and Supabase)
                and UI/UX designers. But if you have skills other than those and you think you can help feel free to
                email us too!
              </p>
            </div>
          </div>
          <div className='w-full items-center gap-4 flex flex-col py-8 h-full '>
            <h1 className=' text-5xl self-center'>Privacy Policy</h1>
            <div className='flex flex-col gap-4 items-center'>
              Thank you for using ringodating.com! Your privacy and security are of utmost importance to us. This Privacy Policy outlines how we collect, use, and protect your personal information. By accessing or using our services, you agree to the terms of this Privacy Policy.<br />
              <div className='flex flex-col items-center'>
                <h2>Information We Collect</h2>

                1.1. Personal Information: When you register for an account on ringodating.com, we collect certain personal information, such as your email address for verification purposes. We do not collect any additional personal information without your explicit consent.<br />

                1.2. Usage Information: We may collect information about your interactions with our platform, including but not limited to the pages you visit, the features you use, and the actions you take while using our service.<br />

                1.3. Cookies and Tracking Technologies: Like many websites, we use cookies and similar tracking technologies to enhance your experience, analyze trends, and administer our site. You can control cookies through your browser settings and other tools.<br />
              </div>
              <div className='flex flex-col items-center'>
                <h2>How We Use Your Information</h2>

                2.1. Verification: We use your email address to verify your identity and ensure the security of your account.<br />

                2.2. Communication: We may use your email address to send you important updates about your account, notifications about matches or messages, and promotional messages about our services. You can opt-out of promotional messages at any time.<br />

                2.3. Improving Our Services: We analyze the information we collect to understand how users interact with our platform and to improve the quality of our services, including the effectiveness of our matching algorithms and user experience.<br />
              </div>
              <div className='flex flex-col items-center'>
                <h2>Information Sharing and Disclosure</h2>

                3.1. We do not sell, rent, or share your personal information with third parties for their marketing purposes.<br />

                3.2. We may share your information with trusted third-party service providers who assist us in operating our platform, conducting business, or servicing you, as long as they agree to keep this information confidential.<br />

                3.3. We may disclose your information in response to legal requests or to comply with applicable laws, regulations, or legal processes.<br />
              </div>
              <div className='flex flex-col items-center'>
                <h2>Data Security</h2>

                4.1. We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.<br />
              </div>
              <div className='flex flex-col items-center'>
                <h2>Children's Privacy</h2>

                5.1. Our services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children under 18. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will take steps to remove that information from our systems.<br />
              </div>
              <div className='flex flex-col items-center'>
                <h2>Changes to This Privacy Policy</h2>

                6.1. We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the new Privacy Policy on this page.<br />
              </div>
              <h2>Contact Us</h2>
              <div className='flex flex-col items-center'>
                7.1. If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at ringodating@protonmail.com.<br />

                Thank you for trusting ringodating.com with your personal information. We are committed to protecting your privacy and providing you with a safe and enjoyable experience on our platform.<br />

                ringodating.com Team<br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default FAQ