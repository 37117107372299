import React from 'react'

const WomanSvg = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="24" viewBox="0 0 640.000000 1280.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M3161 12550 c-453 -96 -865 -567 -932 -1067 -86 -644 483 -1306 1091
-1270 242 15 476 130 681 336 186 186 299 392 350 636 18 86 15 293 -5 390
-83 387 -347 734 -680 895 -55 27 -128 56 -161 65 -71 19 -280 28 -344 15z"/>
                <path d="M2318 10029 c-299 -44 -539 -217 -658 -472 -16 -34 -220 -690 -454
-1457 -233 -767 -451 -1478 -484 -1580 -112 -344 -137 -462 -130 -616 4 -85 9
-111 32 -157 73 -149 250 -217 386 -147 103 52 216 192 313 385 117 234 167
378 423 1218 102 334 194 627 205 652 29 65 66 105 98 105 63 0 65 -39 12
-187 -21 -59 -51 -153 -66 -208 -78 -290 -364 -1278 -711 -2452 -112 -381
-204 -697 -204 -703 0 -7 175 -10 540 -10 l540 0 3 -1877 c2 -1777 3 -1881 21
-1938 69 -232 213 -345 439 -345 230 0 388 133 459 387 l23 78 5 1800 c5 1775
5 1801 25 1840 14 29 27 41 46 43 57 7 54 109 59 -1868 l5 -1815 23 -73 c77
-250 233 -381 453 -382 191 0 338 112 416 319 54 142 52 75 53 2024 l0 1807
540 0 c297 0 540 2 540 5 0 3 -56 220 -124 483 -272 1043 -494 1904 -627 2439
-153 614 -158 644 -107 673 25 13 29 13 43 -1 9 -9 38 -101 64 -205 27 -104
118 -418 204 -699 85 -280 176 -581 201 -668 114 -388 222 -639 322 -746 69
-75 103 -91 191 -91 342 1 452 266 338 816 -33 156 -49 211 -361 1236 -146
480 -290 972 -321 1093 -30 121 -77 281 -105 355 -28 74 -75 209 -104 300 -30
91 -72 201 -94 245 -79 159 -184 270 -315 336 -135 67 -71 63 -1135 65 -531 1
-991 -2 -1022 -7z"/>
            </g>
        </svg>
    )
}

export default WomanSvg