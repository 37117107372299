import React, { ChangeEvent, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { supabase } from '../utils/supabase';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { CountryDropdown } from "react-country-region-selector";
import { useNavigate, useRouteError } from 'react-router-dom';
import { User } from '@supabase/supabase-js';
import { UUID } from 'crypto';
import '../App.css'
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import { useSnackbar } from '../hooks/useSnackbar';

enum genderEnum {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other'
}

const ProfileSchema = z.object({
  name: z.string().min(1).max(12),
  age: z.number().max(99).min(18),
  gender: z.string().min(1),
  country: z.string().min(1),
  bio: z.string().min(30).max(120),
  pfp_link: z.string().optional()
})

type ProfileSchemaType = z.infer<typeof ProfileSchema>;
interface Params {
  id: UUID
}

const Profile = (props: Params) => {
  const [userData, setUserData] = useState<User>()
  const [refreshImage, setRefreshImage] = useState(false)
  const [showCrop, setShowCrop] = useState(false);
  const [anImageWasUploaded, setAnImageWasUploaded] = useState(false);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px', // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  })
  const [finalCrop, setFinalCrop] = useState<Crop>({
    unit: 'px', // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  })
  type Base64<imageType extends string> = `data:image/${imageType};base64${string}`

  const [croppedImage, setCroppedImage] = useState<File>()
  const imgRef = useRef<HTMLImageElement>(null)
  const cropRef = useRef<ReactCrop>(null)
  const navigate = useNavigate();
  const [imgLink, setImgLink] = useState<string>()
  const snackbar = useSnackbar()

  useLayoutEffect(() => {
    async function getUser() {
      try {
        const { data, error } = await supabase.auth.getSession()
        if (data.session === null) {
          navigate("/")
        } else {
          setUserData(data.session.user)
        }
        if (error) {
          throw error
        }
      } catch (e) {
        console.log(e)
      }
    }
    getUser()
  }, [])

  useEffect(() => {
    if (finalCrop.width && finalCrop.height) {
      const canvas = document.createElement("canvas");
      const image = imgRef.current;
      // NOTE: refer to https://github.com/sekoyo/react-image-crop/issues/529#issuecomment-1405064207
      if (image) {
        const crop = finalCrop;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        image.crossOrigin = "anonymous"
        if (ctx) {
          ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
          ctx.imageSmoothingQuality = "low";

          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
          );
        }
        const base64Image = canvas.toDataURL("image/webp") as Base64<'webp'>;
        // NOTE: refer to: https://stackoverflow.com/a/38935990
        function dataURLtoFile(dataurl: string, filename: string) {
          try {
            var arr = dataurl.split(','),
              mime = arr[0].match(/:(.*?);/)[1],
              bstr = atob(arr[arr.length - 1]),
              n = bstr.length,
              u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
          } catch (error) {
            console.log(error)
          }

        }
        var file = dataURLtoFile(base64Image, `image.webp`);
        setCroppedImage(file)
      }
    }

  }, [userData, finalCrop, refreshImage])

  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    setShowCrop(true)
    setAnImageWasUploaded(true)
    // NOTE: refer to https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
    const image = imgRef.current
    const file = e.target.files[0]
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        image!.src = reader.result as string;
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }


  const onSubmit: SubmitHandler<ProfileSchemaType> = async (submittedData) => {
    console.log(submittedData)
    setRefreshImage(true)
    try {
      if (anImageWasUploaded) {
        const { data: imageData, error: ImageError } = await supabase.storage.from('pfps').upload(
          `/${userData!.id}.webp`, croppedImage!,
          { contentType: 'image/webp', upsert: true })
        if (ImageError) {
          throw ImageError
        } else {
          const { data: imageUrlData } = supabase.storage.from('pfps').getPublicUrl(`/${userData!.id}.webp`)
          const { data, error } = await supabase.from("profiles").upsert({ ...submittedData, pfp_link: `${imageUrlData.publicUrl}?${new Date().getTime()}` }).eq('id', userData!.id)
          if (error) {
            throw error
          }

        }
      } else {
        const { data, error } = await supabase.from("profiles").upsert({ ...submittedData }).eq('id', userData!.id)
        if (error) {
          throw error
        }
      }

      snackbar.showSuccessMessage("Profile Successfully Updated.")
      setTimeout(() => {
        // window.location.reload();
        navigate("/dashboard");
      }, 1000)
    } catch (error) {
      console.log(error)
    }
  };


  const {
    unregister,
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<ProfileSchemaType>({
    resolver: zodResolver(ProfileSchema),
    defaultValues: async () => {
      const { data, error } = await supabase.from("profiles").select().eq("id", props.id).single()

      // this sets the default image to the users image
      try {
        setImgLink(data!.pfp_link!)
        if (data!.pfp_link) {
          // setShowCrop(true)

        }
      } catch (error) {
        console.log(error)
      }
      return data!
    }

  });

  return (
    <div className='flex items-center flex-col justify-center bg-base-200 max-w-96 xs:max-w-80 w-96 sm:w-full rounded-3xl p-8 self-center gap-4'>
      <h1 className=' font-[600] text-lg'>Profile</h1>
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full items-center gap-4'>
        <div className='w-full flex flex-col gap-2'>
          <label htmlFor='name'>*Name</label>
          <input className="input input-bordered" placeholder="name (max 12 characters)" {...register("name")} />
          {errors.name && <span className='text-red-700'>{errors.name.message}</span>}
        </div>

        <div className='w-full flex flex-col gap-2'>
          <label htmlFor='age'>*Age (min 18)</label>
          <input type='number' max={99} min={18} className="input input-bordered" placeholder="age" {...register("age", { valueAsNumber: true })} />
          {errors.age && <span className='text-red-700'>{errors.age.message}</span>}
        </div>

        <div className='w-full flex flex-col gap-2'>
          <label htmlFor='gender'>*Gender</label>
          <select {...register("gender")} className='select select-bordered'>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          {errors.gender && <span className='text-red-700'>{errors.gender.message}</span>}
        </div>

        <div className='w-full flex flex-col gap-2'>
          <label htmlFor='country'>*Country</label>
          <Controller
            name="country"
            render={({ field: { name, onChange, value } }) => (
              <CountryDropdown
                defaultOptionLabel="Select Country"
                name={name}
                value={value}
                onChange={onChange}
                // @ts-ignore NOTE: this works anyways
                className='select select-bordered'
              />
            )}
            control={control}
          />
        </div>
        <div className='w-full flex flex-col gap-2'>
          <label htmlFor='bio'>*Bio</label>
          <textarea className="textarea textarea-bordered h-36 resize-none" placeholder="bio (min 30 characters, max 120 characters)" {...register("bio")} />
          {errors.bio && <span className='text-red-700'>{errors.bio.message}</span>}
        </div>
        <div className='flex flex-col gap-2'>

          <label>Profile Pic (optional)</label>
          {
            <ReactCrop
              ref={cropRef}
              crop={crop}
              aspect={1}
              style={showCrop ? { display: 'block' } : { display: 'none' }}
              onDragEnd={() => {
                setFinalCrop(crop)
              }}

              onChange={(crop, percentcrop) => {
                setCrop(crop)
              }}>


              <img ref={imgRef} className='w-full object-cover h-full ' src={control._defaultValues.pfp_link ? imgLink : ''} />


            </ReactCrop>

          }
          {
            showCrop === false && control._defaultValues.pfp_link ? (
              <img className='w-full object-cover h-full ' src={imgLink} />
            ) : (<></>)
          }

          {control._defaultValues.pfp_link ? <></> : <span className=' opacity-70 text-sm'>You haven't uploaded a pfp yet.</span>}
          <input type='file' className='input file-input w-full input-bordered text-sm file-input-bordered' onChange={(e) => {
            handleFileChange(e)
          }} />
          <p className=' opacity-70 text-sm'>*Profile picture may take a few minutes to update.</p>

        </div>


        <button className='btn btn-success w-full' type='submit' onClick={() => {
          unregister('pfp_link')
        }}>Submit Profile</button>
      </form>
    </div>
  )
}
export default Profile 
