import React, { useEffect, useRef, useState } from 'react'
import { supabase } from '../../utils/supabase'
import UserCard from "./UserCard"
import { UUID } from 'crypto'
import { Link, useNavigate } from 'react-router-dom'
import { RealtimePostgresUpdatePayload, User } from '@supabase/supabase-js'
import { Tab, Tabs, TabsList } from "@mui/base"
import { Badge, Box } from "@mui/material"
import InviteCard from './InviteCard'
import ChatCard from './ChatCard'
import Profile from '../../components/Profile'
import { UserInterface } from '../../types/user'
import MailIcon from '@mui/icons-material/Mail';
import ChatIcon from '@mui/icons-material/Chat';
import PersonIcon from '@mui/icons-material/Person';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { shuffle } from '../../utils/utils'
import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import OnlineUserCard from './OnlineUserCard'
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import { useSnackbar } from '../../hooks/useSnackbar'
import RealtimeInviteCard from './RealtimeInviteCard'
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import { Tables } from '../../types/supabase'
import Config from '../../components/Config'
import LogoSvg from '../../components/svgs/LogoSvg'
import PayPalBtn from './PaypalBtn'
import ButtonWrapper from './ButtonWrapper'

interface ProfilesWithAmountToShow {
	profiles: Array<Tables<"profiles">> | undefined,
	showItems?: number
}

// Should be 10 on release, this is just for testing
const USERS_TO_SHOW = 5

const DashBoard = () => {
	const [profilesArr, setProfileArr] = useState<ProfilesWithAmountToShow>({ profiles: [], showItems: USERS_TO_SHOW })
	const [userData, setUserData] = useState<User>()
	const [userProfile, setUserProfile] = useState<Tables<"profiles">>()
	const [invitations, setInvitations] = useState<Array<any>>([])
	const [chats, setChats] = useState<Array<any>>([])
	const [latestMessagesData, setLatestMessagesData] = useState<Array<Tables<'messages'>>>([])
	const [realTimeInvites, setRealTimeInvites] = useState<Array<Tables<'realtime_invites'>>>([])
	const [userConfigData, setUserConfigData] = useState<Tables<"user_config">>()
	const [currentTab, setCurrentTab] = React.useState(0);
	// NOTE: This state change is just for the useEffect Dependencies I doubt we would ever need the data 
	// from accepted and recieved Invites
	const [acceptedInvites, setAcceptedInvites] = useState(0);
	const [receivedInvites, setReceivedInvites] = useState(0);
	const [updateChats, setUpdateChats] = useState(0);
	const [loading, setLoading] = useState(false);

	const [receivedRealtimeInvite, setReceivedRealtimeInvite] = useState(0)
	const [acceptRealTimeInvite, setAcceptRealTimeInvite] = useState(0)
	const buttonRef = useRef<HTMLButtonElement>(null)
	const loadingRef = useRef<HTMLSpanElement>(null)
	const navigate = useNavigate();
	const snackbar = useSnackbar();
	const theme = useTheme();

	const onlineUsersRef = useRef<Array<any>>([])
	// NOTE: get user profile from table
	useEffect(() => {
		async function getUserProfile() {
			try {
				const { data: SessionData, error: SessionError } = await supabase.auth.getSession()
				setUserData(SessionData.session?.user)
				if (SessionData.session === null) {
					navigate("/")
				}
				if (SessionError) {
					throw SessionError
				}
				const { data: ProfileData, error: ProfileError } = await supabase.from("profiles").select().eq('id', SessionData!.session!.user.id).single()
				if (ProfileError) {
					navigate("/complete-profile")
					throw ProfileError
				}
				setUserProfile(ProfileData)
				const { data: ConfigData } = await supabase.from("user_config").select().eq('id', SessionData!.session!.user.id).single().throwOnError()
				setUserConfigData(ConfigData!)
			} catch (error) {
				console.log(error)
			}
		}
		getUserProfile();
	}, [])

	useEffect(() => {
		// NOTE: get other users profiles
		async function getProfiles() {
			const { data: UserData, error: UserError } = await supabase.auth.getSession()
			if (UserError) {
				throw UserError
			}
			// NOTE: only show users who were active in the last 24 hours, I've turned this off for now cos we dont have enough users
			const { data, error } = await supabase.from("profiles").select()
			// .gt('last_online_at', new Date(Date.now() - 86400000).toISOString());
			const filterOutCurrentUser = data?.filter((user) =>
				user.id !== UserData.session?.user.id && user.id
			)
			const filterBasedOnConfig = filterOutCurrentUser?.filter((user) => {
				if (userConfigData === undefined) {
					return user
				}
				if (userConfigData.age === null && userConfigData.gender === null && userConfigData.country === null) {
					return user
				} else {
					if (userConfigData!.age! >= user.age! && userConfigData?.gender === user.gender && userConfigData.country === user.country) {
						return user
					}
				}
			})
			setProfileArr({ profiles: filterBasedOnConfig, showItems: USERS_TO_SHOW })
		}
		getProfiles();
	}, [userConfigData])

	useEffect(() => {
		const onlineUsers = supabase.channel('users_online')

		async function setOnline() {
			const { data: UserData, error: UserError } = await supabase.auth.getSession()
			// TODO: not exactly sure if this is needed, need to read up on the docs (userStatus and presenceTrackStatus)
			const userStatus = {
				user: UserData!.session!.user.id,
				online_at: new Date().toISOString(),
			}

			onlineUsers.on('presence', { event: 'sync' }, () => {
				const newState = onlineUsers.presenceState()
				onlineUsersRef.current = Object.values(newState)
			})
				.on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'realtime_invites', filter: `invited_user=eq.${UserData!.session!.user.id}` }, handleAcceptRealtimeInvite)
				.on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'realtime_invites', filter: `invited_user=eq.${UserData!.session!.user.id}` }, handleReceiveRealtimeInvite)
				.subscribe(async (status) => {
					if (status !== 'SUBSCRIBED') {
						return
					}
					const presenceTrackStatus = await onlineUsers.track(userStatus)
				})
			const { data: configData } = await supabase.from("user_config").select("declined_users").eq("id", UserData!.session!.user.id).maybeSingle()
			if (configData !== null && configData.declined_users !== null && configData!.declined_users!.length !== 0) {
				onlineUsersRef.current = onlineUsersRef.current.filter((user) => {
					// this user has been declined
					if (configData?.declined_users!.includes(user)) {
						return
					} else {
						return user
					}
				})
			}

		}
		setOnline()

	}, [])

	async function handleAcceptRealtimeInvite() {
		setAcceptRealTimeInvite(prev => prev + 1)
	}
	async function handleReceiveRealtimeInvite() {

		setReceivedRealtimeInvite(prev => prev + 1)

	}

	useEffect(() => {
		async function getRealtimeInvites() {
			const { data: UserData, error: UserError } = await supabase.auth.getSession()

			const { data, error } = await supabase.from("realtime_invites").select().eq('invited_user', UserData!.session!.user.id).eq("accepted", false).eq("declined", false)
			if (data !== null) {
				setRealTimeInvites(data)

			}
		}
		getRealtimeInvites()
	}, [userData, receivedRealtimeInvite, acceptedInvites])

	useEffect(() => {
		if (receivedRealtimeInvite > 0) {
			snackbar.showInfoMessage("someone sent u an invite, u have 30 seconds to accept.")
		}
		// setCurrentTab(3)
	}, [receivedRealtimeInvite])

	// NOTE: this gets chat invitations
	useEffect(() => {
		async function getInvitations() {
			try {
				const { data: UserData, error: UserError } = await supabase.auth.getSession()
				if (UserError) {
					throw UserError
				}
				const { data: InvitationData, error: InvitationError } = await supabase.from("chatrooms").select().eq('invited_user', UserData!.session!.user.id).eq("accepted", false)
				if (InvitationError) {
					throw InvitationError
				}
				if (InvitationData === null) {
					throw new Error("invitation data should not be null")
				}
				setInvitations(InvitationData)
			} catch (error) {
				console.log(error)
			}
		}
		getInvitations()
	}, [acceptedInvites, receivedInvites])

	// NOTE: this gets chats
	useEffect(() => {
		async function getChats() {
			try {
				const { data: UserData, error: UserError } = await supabase.auth.getSession()
				if (UserError) {
					throw UserError
				}
				const { data: chatData, error: chatError } = await supabase.from("chatrooms").select()
					.or(`invited_user.eq.${UserData.session?.user.id},inviting_user.eq.${UserData.session?.user.id}`)
					.eq("accepted", true).order('latest_message_at', { ascending: true })
				if (chatError) {
					throw chatError
				}
				//  check if there are unread messages
				const messageDataArr: Array<Tables<'messages'>> = []

				for (let i = 0; i < chatData.length; i++) {
					const { data: messagesData, error: messagesError } = await supabase.from("messages")
						.select()
						.eq("chatroom_id", chatData[i].id)
						.order('created_at', { ascending: false })
						.limit(1)
						.maybeSingle()
					if (messagesData) {
						messageDataArr.push(messagesData)
					}

					if (messagesError) {
						throw messagesError
					}
				}
				setChats(chatData)
				setLatestMessagesData(messageDataArr)
			} catch (error) {
				console.log(error)
			}

		}
		getChats()

	}, [updateChats, acceptedInvites, acceptRealTimeInvite, receivedInvites])


	useEffect(() => {
		const handleAcceptInvite = () => {
			setAcceptedInvites((prev) => (prev + 1))
			setCurrentTab(1)
		}
		const handleInviteWasAccepted = (payload: RealtimePostgresUpdatePayload<Tables<"chatrooms">>) => {
			setAcceptedInvites((prev) => (prev + 1))
			setCurrentTab(1)
			snackbar.showSuccessMessage(`${payload.new.invited_user} accepted your invite.`)
		}
		function handleReceiveInvite() {
			setReceivedInvites((prev) => (prev + 1))
		}
		function handleMessageUpdate() {
			setUpdateChats((prev) => (prev + 1))

		}
		// Listen to database changes
		try {
			supabase
				.channel('chatrooms')
				.on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'chatrooms', filter: `invited_user=eq.${userData?.id}` }, handleAcceptInvite)
				.on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'chatrooms', filter: `inviting_user=eq.${userData?.id}` }, handleAcceptInvite)
				// probably an or filter ? 
				.on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'chatrooms', filter: `invited_user=eq.${userData?.id}` }, handleReceiveInvite)
				.subscribe()
			supabase
				.channel('messages')
				.on('postgres_changes', {
					event: 'INSERT', schema: 'public', table: 'messages', filter: `chatroom_id=in.(${latestMessagesData.flatMap((messages) => {
						return messages?.chatroom_id
					}).join()})`
				}, handleMessageUpdate).subscribe()
		} catch (error) {
			console.log(error)
		}

	}, [userData, latestMessagesData])

	useEffect(() => {
		async function updateLastOnline() {
			try {
				const { data: UserData, error: UserError } = await supabase.auth.getSession()
				if (UserError) {
					throw UserError
				}
				const { error: updateLastOnlineError } = await supabase.from("profiles")
					.update({ last_online_at: new Date().toISOString() })
					.eq("id", UserData!.session!.user.id)
				if (updateLastOnlineError) {
					throw updateLastOnlineError
				}
			} catch (error) {
				console.log(error)
			}

		}
		updateLastOnline()
	})

	const handleTabChange = (e: React.SyntheticEvent | null, newValue: number) => {
		setCurrentTab(newValue as number);

	};
	const handleChangeIndex = (index: number) => {
		setCurrentTab(index);
	};
	function CustomTabPanel(props: any) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
				className="min-h-[85dvh]"
			>
				{value === index && (
					<Box>
						<div className={`${props.className} w-[100dvw] px-6`}>{children}</div>
					</Box>
				)}
			</div>
		);
	}

	async function handleSignOut() {
		const { error } = await supabase.auth.signOut()
		if (error) {
			console.log(error)
		}
		navigate("/?signOut")
	}

	function tabHeaderStyle(tabNum: number) {
		return `text-base-content px-2 flex items-center justify-center rounded-xl font-[600] h-8 w-16 text-center
        ${currentTab === (tabNum)
				? 'bg-primary text-white' :
				'text-base-content bg-transparent hover:bg-primary hover:text-white'}`
	}

	function handleReportBugs() {
		// @ts-ignore daisyui
		document.getElementById('report_dialog').showModal()
	}
	const ReportBugSchema = z.object({
		reason: z.string().min(30)
	})
	type ReportBugSchemaType = z.infer<typeof ReportBugSchema>;
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm<ReportBugSchemaType>({ resolver: zodResolver(ReportBugSchema) });
	const onSubmit: SubmitHandler<ReportBugSchemaType> = async (submittedData) => {
		const { error: reportError } = await supabase.from("reports")
			.insert({ reported_user_id: null, reason: submittedData.reason, category: 'Bug' })
		if (reportError) {
			console.log(reportError)
		}
		reset()
	}
	return (
		<html>
			<head>

				<title>Ringo | Dashboard</title>
			</head>
			<body className='xs:flex xs:flex-col xs:items-center'>
				<Link to="/dashboard" className='w-full flex flex-col items-center'><LogoSvg /></Link>

				<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5306180526243960"
					crossOrigin="anonymous"></script>
				<div className='flex flex-row justify-evenly w-full items-center'>
					<h1>Welcome back, {userProfile?.name}</h1>
					<button className="btn text-base-content" onClick={() => handleReportBugs()}>Report Bugs</button>

				</div>
				<dialog id="report_dialog" className="modal">
					<div className=" gap-4 modal-box flex flex-col items-center">
						<h3 className="font-bold text-lg">Report Bugs</h3>
						<form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col items-center gap-4">
							<textarea className="w-full h-36 textarea resize-none textarea-bordered" {...register("reason")}
								placeholder='Report any bugs with necessary details (min 30 characters)' />
							{errors.reason && <span className='text-red-700'>{errors.reason.message}</span>}
							<button type='submit' className='btn w-full btn-success'>Submit</button>
						</form>
					</div>
					<form method="dialog" className="modal-backdrop">
						<button>close</button>
					</form>
				</dialog>
				<Link to="/FAQ" className='h-[4rem] rounded-lg mx-12 sm:mx-0 border-[3px] flex items-center flex-col justify-center border-success'>
					<p className='font-[600]'>We have updated our FAQ and Privacy Policy! Also information on careers at Ringo.</p>
				</Link>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs value={currentTab} onChange={handleTabChange!} >
						<TabsList className={
							'xs:flex-wrap mb-4 rounded-xl gap-4 py-4 min-[600px]:p-0 flex font-sans items-center justify-center content-between w-full shadow-lg'
						}>
							<Tab className={tabHeaderStyle(0)}><TravelExploreIcon /></Tab>
							<Tab className={tabHeaderStyle(1)}>
								<Badge badgeContent={latestMessagesData.filter((message) => {
									if (message?.user_id !== userData!.id && message?.read !== true) {
										return message
									}
								}).length} color="error">
									<ChatIcon />
								</Badge>
							</Tab>
							<Tab className={tabHeaderStyle(2)}>
								<Badge badgeContent="New!" color='error'>
									<QuickreplyIcon />
								</Badge>
							</Tab>

							<Tab className={tabHeaderStyle(3)}>
								<Badge badgeContent={invitations.filter((invitation) => {
									return invitation.accepted !== true
								}).length + realTimeInvites.length} color="error">
									<MailIcon />
								</Badge>
							</Tab>
							<Tab className={tabHeaderStyle(4)}><PersonIcon /></Tab>

						</TabsList>
						<SwipeableViews
							axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
							index={currentTab}
							onChangeIndex={handleChangeIndex}>
							<CustomTabPanel value={currentTab} index={0} dir={theme.direction} className=" max-w-[100dvw] flex flex-col items-center gap-4">
								<p className='pb-2 text-center'>Start inviting users! Users are currently limited to 30 invites per day.</p>
								{/* <br />Only users who have been active in the past 24 hours will be shown.</p> */}
								<button className='block' ref={buttonRef}
									onClick={() => {
										setLoading(true)
										setProfileArr({
											profiles: profilesArr.profiles,
											showItems: profilesArr.profiles !== undefined ? profilesArr.showItems! >= profilesArr.profiles.length ? USERS_TO_SHOW : profilesArr.showItems! + USERS_TO_SHOW : 0
										})
										setTimeout(() => {
											setLoading(false)
										}, 500)
									}}>
									{loading ? (
										<span id='loading_span' ref={loadingRef}
											className='loading loading-spinner loading-lg'></span>
									) : (
										<svg id="Layer_1" data-name="Layer 1" height="36" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.04 122.88"><path d="M16.08,59.26A8,8,0,0,1,0,59.26a59,59,0,0,1,97.13-45V8a8,8,0,1,1,16.08,0V33.35a8,8,0,0,1-8,8L80.82,43.62a8,8,0,1,1-1.44-15.95l8-.73A43,43,0,0,0,16.08,59.26Zm22.77,19.6a8,8,0,0,1,1.44,16l-10.08.91A42.95,42.95,0,0,0,102,63.86a8,8,0,0,1,16.08,0A59,59,0,0,1,22.3,110v4.18a8,8,0,0,1-16.08,0V89.14h0a8,8,0,0,1,7.29-8l25.31-2.3Z" /></svg>
									)}
								</button>
								<div className=' gap-4 grid grid-flow-row grid-cols-3 justify-center items-center 
                                 lg:grid-cols-2 md:flex flex-col h-full'>
									{/* shuffle the array */}
									{shuffle(profilesArr.profiles)
										.slice(profilesArr.showItems! - USERS_TO_SHOW, profilesArr.showItems)?.map((user: UserInterface) => {
											if (loading) {
												return (
													<div className='w-[28rem] sm:w-[20rem] h-56 p-9 skeleton rounded-2xl flex flex-col justify-between'>
														<div className='flex flex-row justify-between items-center'>
															<div className="skeleton bg-[#201e1e] h-4 w-28"></div>
															<div className='skeleton w-16 h-16 rounded-full shrink-0 bg-[#201e1e]'></div>
														</div>
														<div className="skeleton bg-[#201e1e] h-4 w-60"></div>
													</div>)
											} else {
												return (
													<UserCard key={user.id} {...user} />
												)
											}
										})
									}
								</div>
							</CustomTabPanel>
							<CustomTabPanel value={currentTab} index={1} dir={theme.direction}>
								{
									chats.length === 0 ? (<p className='pb-2 w-full text-center'>No chats yet! Try sending out more invites!</p>) : (<></>)
								}
								<div className='flex flex-col-reverse gap-6 items-center w-full '>

									{chats?.map((chat) => (
										<ChatCard {...chat} key={chat.id} />
									))}
								</div>
							</CustomTabPanel>
							<CustomTabPanel value={currentTab} index={2} dir={theme.direction}>
								<div className='flex flex-col gap-4 items-center'>
									<span className='text-center'>Invite users who are currently online! Users have 30 seconds to accept Invite.</span>
									{
										onlineUsersRef.current.filter((user) => (user[0].user !== userData?.id)).map((user) => {

											return <OnlineUserCard id={user[0].user} />
										})
									}

								</div>
							</CustomTabPanel>
							<CustomTabPanel value={currentTab} index={3} dir={theme.direction}>
								<div className='flex flex-col gap-6 items-center'>
									{
										invitations.filter((invitation) => {
											return invitation.accepted !== true
										}).length === 0 ? (<p className='pb-2 text-center'>No pending invites yet! Send invites on the explore page to initiate chats.</p>
										) : (<></>)
									}
									<div className='flex flex-col-reverse items-center w-full'>
										{
											realTimeInvites.map((realtimeInvite) => (
												<RealtimeInviteCard key={realtimeInvite.id} inviting_user={realtimeInvite.inviting_user as UUID} id={realtimeInvite.id as UUID} />
											))
										}
									</div>
									{
										invitations?.map((invite) => (
											<InviteCard id={invite.inviting_user} chatroomId={invite.id} key={invite.id} />
										))
									}
								</div>
							</CustomTabPanel>
							<CustomTabPanel value={currentTab} index={4} dir={theme.direction}>
								<div className='flex items-center flex-col gap-4 py-4'>
									{userData ? (
										<>
											<ButtonWrapper type="subscription" />
											<Profile id={userData.id as UUID} />
											<Config id={userData.id as UUID} />
										</>
									) : (<p>Loading...</p>)}
									<button className="btn btn-error max-w-96 w-96 sm:w-full" onClick={() => handleSignOut()}>Sign out</button>
								</div>
							</CustomTabPanel>
						</SwipeableViews>
					</Tabs>
				</Box>

			</body>
		</html>
	)
}

export default DashBoard
