import './App.css';
import Signup from './pages/SignUp/Signup.tsx';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CompleteProfile from './pages/CompleteProfile/CompleteProfile.tsx';
import DashBoard from './pages/DashBoard/DashBoard.tsx';
import Chat from './pages/Chat/Chat.tsx';
import Layout from './components/Layout.tsx';
import Error from './pages/Error/Error.tsx'
import Config from './components/Config.tsx'
import FAQ from './pages/FAQ/FAQ.tsx'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const initialOptions = {
  clientId: "Ac7EjTnoWt6JCK808HEdEUAyUbRBGr6hbNKCmvX_wDu6DJiQxwsQcVALufh5U3ALs4rELTd2qALnIvHU",
  currency: "USD",
  intent: "capture",
  vault: true
};
function App() {
  return (
    <PayPalScriptProvider options={initialOptions}>
      <BrowserRouter>
        <Layout>

          <Routes>
            <Route index element={<Signup />}>
            </Route>
            <Route path='/:signOut' element={<Signup />}>
            </Route>
            <Route path='/complete-profile' element={<CompleteProfile />}>
            </Route>
            <Route path='/dashboard' element={<DashBoard />}>
            </Route>
            <Route path='/chat/:id' element={<Chat />}>
            </Route>
            <Route path='/error' element={<Error />}>
            </Route>
            <Route path='/config' element={<Config />}>
            </Route>
            <Route path='/FAQ' element={<FAQ />}>
            </Route>
            {/*<Route path='/payment' element={<Payment />}>
          </Route>*/}
          </Routes>
        </Layout>

      </BrowserRouter>
    </PayPalScriptProvider>
  );
}

export default App
