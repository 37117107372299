import React, { useEffect, useState } from 'react'
import { supabase } from '../../utils/supabase';
import { Link, useNavigate } from 'react-router-dom';
import Profile from '../../components/Profile';
import { UUID } from 'crypto';
import Config from '../../components/Config';
import { useSnackbar } from '../../hooks/useSnackbar';
import LogoSvg from '../../components/svgs/LogoSvg';


const CompleteProfile = () => {
    const [userId, setUserId] = useState<UUID>()
    const navigate = useNavigate();
    const snackbar = useSnackbar()
    useEffect(() => {
        async function getUser() {
            try {
                const { data, error } = await supabase.auth.getSession()
                if (data.session === null) {
                    navigate("/")
                }
                else {
                    setUserId(data.session.user.id as UUID)
                }
                if (error) {
                    throw error
                }
            } catch (e) {
                console.log(e)
            }
        }

        getUser()
    }, [])

    async function handleClick() {
        try {
            const { data: profileData } = await supabase.from("profiles").select().eq("id", userId!).maybeSingle().throwOnError()
            const { data: configData } = await supabase.from("user_config").select().eq("id", userId!).maybeSingle().throwOnError()
            if (profileData?.id !== null && configData?.id !== null) {
                navigate("/dashboard")
            } else {
                snackbar.showErrorMessage("One of these forms have not been submitted.")
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Link to="/dashboard" className='w-full flex flex-col items-center'><LogoSvg /></Link>

            <div className='flex flex-col gap-4 pb-8 break-words max-w-96 xs:max-w-80 items-center'>
                <h1 className="text-center">You're almost done! Complete your profile and preferences in the forms below.</h1>
                <Profile id={userId!} />
                {/* <Config id={userId!} /> */}
                <button className='btn btn-primary w-full' onClick={() => handleClick()}>Proceed to Dashboard</button>
            </div>
        </>

    )
}

export default CompleteProfile
