import React from 'react'
import { Link } from 'react-router-dom'
import './Logo.css'
const LogoSvg = () => {
    return (
        <button className='my-4'>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="330" height="72" viewBox="0 0 786.000000 253.000000"
                preserveAspectRatio="xMidYMid meet" >
                <g transform="translate(0.000000,253.000000) scale(0.100000,-0.100000)"
                    stroke="none">
                    <path d="M6308 2313 c-70 -70 -128 -134 -128 -143 0 -21 592 -610 613 -610 20
0 617 593 617 613 0 7 -58 70 -128 140 l-129 127 -358 0 -358 0 -129 -127z
m917 -43 l100 -100 -217 -217 c-120 -119 -219 -215 -222 -213 -2 3 25 94 61
203 l65 197 94 0 c80 0 95 3 103 18 17 33 -1 41 -99 44 l-94 3 -22 55 c-23 58
-50 74 -73 46 -10 -12 -11 -23 -2 -47 22 -62 24 -61 -124 -57 l-135 3 3 55 c2
52 1 55 -23 55 -20 0 -27 -9 -45 -55 l-21 -55 -96 -3 c-77 -2 -97 -6 -102 -19
-12 -31 13 -42 107 -45 l92 -3 63 -185 c34 -102 61 -191 60 -198 -2 -7 -100
84 -218 203 l-215 215 100 100 100 100 330 0 330 0 100 -100z m-295 -147 c-1
-30 -128 -398 -136 -396 -10 2 -134 369 -134 395 0 16 13 18 135 18 122 0 135
-2 135 -17z"/>
                    <path d="M5005 1944 c-303 -41 -570 -241 -694 -517 -77 -173 -95 -395 -46
-575 67 -251 226 -445 460 -565 106 -54 135 -64 242 -82 148 -26 333 -11 478
37 89 29 202 86 243 122 69 60 72 76 72 399 0 272 -1 290 -21 333 -14 29 -38
56 -68 77 l-46 32 -262 0 -261 0 -44 -30 c-130 -92 -121 -281 17 -357 44 -24
61 -28 143 -28 l93 0 -3 -77 -3 -77 -35 -9 c-19 -5 -87 -7 -150 -5 -108 4
-119 6 -180 39 -162 86 -250 232 -250 413 0 139 36 224 136 323 138 138 323
170 529 89 51 -19 157 -21 193 -2 118 62 149 237 59 334 -53 57 -194 106 -367
127 -68 8 -127 15 -130 14 -3 -1 -50 -7 -105 -15z m395 -98 c106 -37 150 -65
173 -109 39 -75 -3 -174 -83 -198 -32 -10 -47 -9 -87 5 -251 88 -422 72 -590
-56 -57 -44 -118 -119 -151 -188 -34 -71 -45 -144 -40 -265 4 -104 6 -116 43
-190 69 -144 204 -255 346 -287 86 -19 283 -10 357 17 9 3 12 42 12 145 l0
140 -118 0 c-95 0 -124 4 -151 18 -67 36 -91 132 -50 199 37 60 52 63 309 63
l231 0 42 -39 42 -40 0 -294 0 -294 -28 -36 c-29 -39 -158 -107 -264 -141
-134 -42 -353 -45 -486 -7 -151 44 -308 144 -399 254 -62 76 -122 173 -144
237 -9 25 -20 54 -25 65 -5 11 -14 52 -19 90 -6 39 -13 86 -16 106 -8 44 21
217 47 288 93 254 273 430 527 517 82 28 127 33 287 30 142 -4 169 -7 235 -30z"/>
                    <path d="M535 1932 c-89 -12 -167 -79 -186 -158 -14 -61 -12 -1345 3 -1402 15
-62 77 -123 147 -147 111 -37 212 2 257 98 23 50 24 62 24 255 l0 202 199 0
c196 0 200 0 227 -24 26 -21 29 -33 40 -137 17 -154 49 -235 124 -310 72 -73
107 -90 194 -96 61 -4 77 -1 115 19 25 13 58 40 74 60 57 71 44 195 -26 254
-44 37 -45 39 -61 162 -14 108 -35 163 -92 254 -17 26 -17 27 22 75 91 110
127 206 127 343 2 248 -140 450 -373 531 -62 21 -82 22 -415 25 -192 1 -372
-1 -400 -4z m812 -94 c126 -53 202 -126 265 -256 32 -65 33 -71 33 -192 0
-129 -1 -134 -53 -239 -9 -18 -45 -64 -79 -101 l-62 -67 25 -24 c75 -71 114
-170 129 -334 6 -63 10 -73 44 -104 82 -77 94 -136 38 -197 -83 -91 -250 -35
-322 107 -27 51 -44 134 -54 249 -7 90 -31 130 -91 154 -33 13 -82 16 -274 16
l-234 0 -4 -243 c-3 -237 -4 -243 -26 -272 -13 -17 -33 -35 -45 -42 -56 -31
-165 5 -202 68 -19 33 -20 53 -23 694 -3 699 -2 723 43 765 51 48 75 50 465
47 l365 -3 62 -26z"/>
                    <path d="M710 1345 l0 -245 253 0 c296 0 295 0 345 99 71 143 44 307 -62 372
-28 17 -53 19 -283 19 l-253 0 0 -245z m519 151 c24 -25 51 -103 51 -145 0
-38 -36 -130 -61 -158 -22 -23 -25 -23 -231 -23 l-208 0 0 175 0 175 213 0
c212 0 214 0 236 -24z"/>
                    <path d="M2048 1905 c-50 -26 -78 -60 -96 -114 -9 -24 -12 -229 -12 -725 0
-652 1 -693 19 -731 24 -53 55 -83 105 -106 108 -49 237 -1 287 106 18 38 19
80 19 741 l0 701 -23 44 c-14 27 -42 57 -71 77 -43 28 -56 32 -115 32 -50 -1
-77 -7 -113 -25z m164 -54 c32 -13 73 -60 87 -100 8 -24 11 -226 11 -685 l0
-652 -22 -44 c-30 -57 -65 -82 -125 -87 -39 -4 -55 0 -82 19 -74 50 -69 5 -70
765 -1 782 -6 737 84 780 48 23 67 23 117 4z"/>
                    <path d="M2735 1913 c-40 -21 -83 -62 -103 -98 -15 -27 -17 -98 -17 -751 l0
-720 27 -41 c36 -55 86 -84 156 -90 64 -6 105 9 155 56 63 58 62 50 65 469 2
211 7 382 12 380 4 -2 117 -142 251 -313 489 -626 453 -590 586 -589 64 0 77
3 115 30 24 16 54 50 68 74 l25 45 0 712 0 712 -24 44 c-34 62 -82 90 -161 95
-85 5 -142 -24 -184 -92 l-30 -47 2 -377 c1 -207 -2 -382 -6 -389 -7 -11 -128
136 -332 402 -241 315 -364 461 -403 482 -52 27 -156 31 -202 6z m152 -61 c36
-15 69 -52 249 -282 114 -146 216 -277 228 -292 11 -14 29 -37 39 -50 10 -12
91 -116 180 -230 l162 -207 5 497 c5 477 6 498 25 524 42 56 123 67 180 24 56
-43 57 -56 53 -793 -3 -653 -3 -662 -24 -690 -31 -41 -82 -73 -119 -73 -66 0
-96 23 -200 155 -55 70 -121 153 -146 184 -25 31 -61 77 -80 102 -19 25 -62
81 -97 125 -35 43 -69 86 -75 94 -7 8 -34 42 -60 75 -57 72 -49 62 -125 160
-76 97 -80 101 -114 143 l-28 34 0 -492 c0 -482 0 -492 -21 -521 -42 -59 -102
-73 -168 -39 -73 37 -71 15 -71 769 0 713 0 711 49 755 19 17 85 45 106 45 6
0 29 -8 52 -17z"/>
                    <path d="M6276 1814 c-177 -119 -319 -322 -378 -541 -27 -101 -29 -314 -5
-415 69 -285 244 -506 498 -631 333 -162 717 -112 1005 131 127 108 245 295
291 463 23 83 25 365 4 449 -22 87 -81 218 -129 290 -92 136 -294 310 -342
295 -37 -12 -25 -50 24 -79 265 -155 435 -503 397 -814 -38 -308 -220 -557
-504 -687 -215 -99 -502 -93 -715 14 -224 113 -374 293 -448 538 -23 74 -27
108 -28 218 -1 140 8 189 56 318 59 154 194 320 328 403 59 36 72 58 48 82
-19 19 -28 15 -102 -34z"/>
                    <path d="M6415 1718 c-162 -85 -308 -270 -358 -453 -10 -38 -21 -118 -24 -176
-8 -148 13 -244 79 -379 45 -91 64 -117 144 -197 81 -80 106 -98 196 -142 130
-63 203 -81 343 -81 215 0 386 71 540 225 285 285 301 731 36 1044 -79 92
-227 192 -265 178 -35 -14 -17 -46 53 -91 92 -59 144 -107 197 -182 59 -83
100 -174 118 -266 70 -345 -112 -674 -444 -800 -81 -31 -91 -32 -230 -33 -127
0 -154 3 -215 24 -123 42 -184 80 -280 176 -144 144 -205 289 -205 490 0 167
48 307 148 431 51 63 143 142 207 177 30 17 40 29 40 47 0 33 -28 36 -80 8z"/>
                </g>
            </svg>
        </button>
    )
}

export default LogoSvg