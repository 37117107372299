import { UUID } from 'crypto'
import React, { useEffect, useRef, useState } from 'react'
import ManSvg from '../../components/svgs/ManSvg'
import WomanSvg from '../../components/svgs/WomanSvg'
import { UserInterface } from '../../types/user'
import { supabase } from '../../utils/supabase'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import FavoriteIcon from '@mui/icons-material/Favorite';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Slide } from '@mui/material'
import { Database, Tables } from '../../types/supabase'
interface OnlineUserInterface {
  id: UUID,
}

enum ReportCategoryEnum {
  NameOrBio = "NameOrBio",
  Bullying = "Bullying",
  Pfp = "Pfp"
}

const ReportSchema = z.object({
  reportType: z.nativeEnum(ReportCategoryEnum),
  reason: z.string().min(15)
});

type ReportSchemaType = z.infer<typeof ReportSchema>;

const OnlineUserCard = (props: OnlineUserInterface) => {
  const [startTimer, setStartTimer] = useState(false)
  const [timer, setTimer] = useState<number>(30)
  const [timerReached, setTimerReached] = useState(false)
  const [realTimeInviteId, setRealTimeInviteId] = useState<String>('')
  const [reportSelected, setReportSelected] = useState(false)
  const [reportSubmitted, setReportSubmitted] = useState(false)
  const [slideOut, setSlideOut] = useState(false)
  const countdownRef = useRef()
  const inviteRef = useRef<HTMLButtonElement>(null)
  const radialRef = useRef<HTMLDivElement>(null)
  const mainRef = useRef<HTMLDivElement>(null)
  // @ts-ignore default value
  const [userData, setUserData] = useState<Tables<'profiles'>>({});
  const [genderStyles, setGenderStyles] = useState<any>({ color: 'bg-slate-600', element: <></> })
  useEffect(() => {
    async function getUserInfo() {
      try {
        const { data: UserData, error: UserError } = await supabase.from("profiles").select().eq("id", props.id).single()

        if (UserData !== null) {
          setUserData(UserData)
        }
        if (UserError) {
          throw UserError
        }
      } catch (error) {
        console.log(error)
      }

    }
    getUserInfo()
    async function checkIfThisInviteExists() {
      try {
        const { data: sessionData, error: sessionError } = await supabase.auth.getSession()
        const { data, error } = await supabase.from("realtime_invites").select()
          .eq("inviting_user", sessionData!.session!.user.id)
          .eq("invited_user", props.id)
          .maybeSingle()
        if (data) {
          mainRef.current!.remove()
        }
        if (sessionError) {
          throw sessionError
        }
      } catch (error) {
        console.log(error)
      }
    }
    checkIfThisInviteExists()
  }, [])

  useEffect(() => {
    function getGenderStyles() {
      if (userData.gender === 'Male') {
        return { color: 'bg-primary', element: <ManSvg /> }
      } else if (userData.gender === 'Female') {
        return { color: 'bg-pink-600', element: <WomanSvg /> }
      } else {
        return { color: 'bg-slate-600', element: <></> }
      }
    }
    setGenderStyles(getGenderStyles())
  }, [userData])
  async function handleOnlineInvite() {
    const { data, error } = await supabase.from("realtime_invites").insert({ invited_user: props.id, expire_time: new Date(Date.now() + 30000).toISOString() }).select().single()
    inviteRef.current!.classList.add("hidden")
    radialRef.current!.classList.remove("hidden")
    setStartTimer(true)
    setRealTimeInviteId(data!.id)


    if (error) {
      console.log(error)
    }
  }

  async function handleCancelOnlineInvite() {
    try {
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession()
      if (sessionError) {
        throw sessionError
      }
      const { data: userConfigData, error: userConfigError } = await supabase.from("user_config").select('declined_users').eq("id", sessionData!.session!.user.id).single()
      if (userConfigError) {
        throw userConfigError
      }
      if (userConfigData!.declined_users !== null) {
        let updateDeclinedUsers: string[] = [...userConfigData.declined_users, props.id]
        const { error } = await supabase.from("user_config").update({ declined_users: updateDeclinedUsers }).eq('id', sessionData!.session!.user.id)
        if (error) {
          throw error
        }
      } else {
        let updateDeclinedUsers: string[] = [props.id]
        const { error } = await supabase.from("user_config").update({ declined_users: updateDeclinedUsers }).eq('id', sessionData!.session!.user.id)
        if (error) {
          throw error
        }
      }
      setSlideOut(true)
    } catch (error) {
      console.log(error)
    }
  }

  // TODO : get this clear interval to work
  useEffect(() => {
    if (startTimer) {
      const id = setInterval(() => {
        setTimer((prev) => {
          if (prev > 0) {
            return (prev - 1)
          } else {
            return 0
          }
        })
      }, 1000)
      // @ts-ignore i dont think the clear interval is working
      countdownRef.current = id
    }
  }, [startTimer])

  useEffect(() => {
    if (timer === 0) {
      clearInterval(countdownRef.current)
      setTimerReached(true)
    }
  }, [timer])

  useEffect(() => {
    async function updateDeclined() {
      // TODO: this wont update if user closes app before timerReached, will need edge function
      const { data, error } = await supabase.from("realtime_invites").update({ declined: true }).eq("id", realTimeInviteId).select().single()
      if (error) {
        console.log(error)
      }
    }
    if (timerReached) {
      updateDeclined()
    }
  }, [timerReached])
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ReportSchemaType>({ resolver: zodResolver(ReportSchema) });
  const onSubmit: SubmitHandler<ReportSchemaType> = async (submittedData) => {
    const { error: reportError } = await supabase.from("reports")
      .insert({ reported_user_id: props.id, reason: submittedData.reason, category: submittedData.reportType })
    if (reportError) {
      console.log(reportError)
    }
    setReportSubmitted(true)
    reset()
  }
  if (reportSubmitted) {
    document.getElementById('my_modal_7')!.checked = false
    // @ts-ignore daisyui
    document.getElementById("my_modal_2").showModal();
    document.body.style.overflow = 'auto';

  }
  return (
    <Slide
      in={!slideOut} direction="left" mountOnEnter unmountOnExit
      ref={mainRef} className={`card w-[28rem] sm:w-[20rem] p-4 pb-6 h-[320px] text-primary-content ${genderStyles.color} `}>
      <div className="card-body justify-between">
        <div className='flex flex-row justify-between items-center'>
          <div className='flex flex-row gap-5 items-center text-[4rem]'>
            {userData.pfp_link ? (<div className="avatar ">
              <div className=" w-16 rounded-full">
                <img src={userData.pfp_link} />
              </div>
            </div>) : (<AccountCircleIcon fontSize='inherit' />
            )}
            <div className='flex flex-col'>
              <h2 className="card-title mt-1">
                {userData.name}
              </h2>
              <span className='flex flex-row text-sm leading-relaxed items-center tracking-wide bg-success text-white rounded-lg gap-3 px-4 max-w-24'>
                <div className=' z-[9999] bg-white text-white h-2 rounded-full w-2' />Online</span>
            </div>
          </div>
        </div>

        <p className='text-left break-words h-full pt-2 text-wrap'>{userData.bio}</p>
        <div className=" flex-nowrap flex-col flex gap-2">
          <div className='flex flex-row gap-2 pt-4 text-base self-start font-medium'>
            {
              genderStyles.element
            }
            <span>{userData.age}</span>
            <span className='text-center'>{userData.country}</span>
          </div>
          <div className='flex flex-row max-h-[3rem] justify-between w-full '>
            <button className="h-[3rem] text-[3rem]" onClick={() => handleCancelOnlineInvite()}>
              <HighlightOffIcon fontSize='inherit' />
            </button>
            <div className='max-h-[3rem]'>
              <button className=" h-[3rem] text-[3rem]" ref={inviteRef} onClick={() => handleOnlineInvite()}>
                <FavoriteIcon fontSize='inherit' />
              </button>

              <div className='hidden max-h-[3rem] pt-4' ref={radialRef}>

                <div className={`radial-progress max-h-[3rem] ${timerReached ? 'hidden' : 'inline-grid'}`} style={{
                  // @ts-ignore daisyui
                  "--value": timer * 3.33333333, "color": "white", "--size": "3rem"
                }} role="progressbar">
                  {timer}</div>
                {timerReached ? (<span>They did not accept your invite</span>) : (<></>)}
              </div>
            </div>


          </div>
        </div>
      </div>
    </Slide>
  )
}

export default OnlineUserCard