import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { supabase } from '../../utils/supabase'
import { ChatRoomInterface } from '../../types/chatroom'
import { UUID } from 'crypto'
import { Link } from 'react-router-dom'
import { UserInterface } from '../../types/user'
import { MessageInterface } from '../../types/message'
import { useSnackbar } from '../../hooks/useSnackbar'
import { Tables } from '../../types/supabase'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';



const ChatCard = (props: ChatRoomInterface) => {
  const [oppositeUserId, setOppositeUserId] = React.useState<UUID>()
  const [oppositeUserData, setoppositeUserData] = useState<Tables<"profiles">>()
  const [latestMessageData, setLatestMessageData] = useState<Tables<"messages">>()
  const [currentUserData, setCurrentUserData] = useState<Tables<"profiles">>()
  const [loading, setLoading] = useState(true)
  const messageRef = useRef<HTMLElement>(null)
  const snackbar = useSnackbar()
  useLayoutEffect(() => {
    async function sortUsersById() {
      try {
        const { data: UserData, error: UserError } = await supabase.auth.getSession()
        const { data: currentUserData, error: CurrentUserError } = await supabase.from("profiles").select().eq('id', UserData!.session!.user.id).single();
        if (CurrentUserError) {
          throw CurrentUserError
        }
        setCurrentUserData(currentUserData)

        if (props.invited_user === UserData.session?.user.id) {
          setOppositeUserId(props.inviting_user)
          const { data: OppositeUserData, error: OppositeUserError } = await supabase.from("profiles").select().eq('id', props.inviting_user).single()
          if (OppositeUserError) {
            throw OppositeUserError
          }
          setoppositeUserData(OppositeUserData)
        }
        if (props.inviting_user === UserData.session?.user.id) {
          setOppositeUserId(props.invited_user)
          const { data: OppositeUserData, error: OppositeUserError } = await supabase.from("profiles").select().eq('id', props.invited_user).single()
          if (OppositeUserError) {
            throw OppositeUserError
          }
          setoppositeUserData(OppositeUserData)
        }

        if (UserError) {
          throw UserError
        }
      } catch (error) {
        console.log(error)
      }

    }

    sortUsersById()
    setLoading(false)

  }, [])
  useEffect(() => {
    async function getChatData() {
      try {
        const { data: messagesData, error: messagesError } = await supabase.from("messages")
          .select()
          .eq("chatroom_id", props.id)
          .order('created_at', { ascending: false })
          .limit(1)
          .maybeSingle()

        setLatestMessageData(messagesData!)

        const { data: unreadAmount, error: unreadAmountError } = await supabase.from("messages")
          .select()
          .eq("chatroom_id", props.id)

      } catch (error) {
        console.log(error)
      }

    }

    getChatData()

  }, [])

  if (loading || oppositeUserData === undefined || currentUserData === undefined || latestMessageData === undefined) {
    return <div className='md:w-full w-[1000px] flex flex-col items-center justify-center h-32 shadow-xl bg-base-200 rounded-2xl'>
      <span className="loading loading-spinner loading-lg"></span>
    </div>
  } else {
    if (latestMessageData?.user_id === currentUserData.id || latestMessageData?.read) {
      return (
        <Link className='overflow-x-hidden lg:w-full w-[1000px] flex flex-row p-4 card shadow-xl bg-base-200 justify-between h-32' to={`/chat/${props.id}`} >

          <div className='card-body p-0 h-full flex flex-row gap-4 items-center'>
            {oppositeUserData.pfp_link !== null ? (<img src={oppositeUserData.pfp_link!} className="w-20 sm:w-16  rounded-full" />
            ) : (<div className='sm:text-[64px] text-[80px]'><AccountCircleIcon fontSize='inherit' /></div>)}
            <div className='gap-2 flex flex-col'>
              <h1 className='font-bold text-2xl sm:text-xl'>{oppositeUserData?.name}</h1>
              {latestMessageData === null ? (<p>Click here to begin the conversation!</p>)
                : (<div className='flex flex-row items-center'>
                  {latestMessageData?.user_id === oppositeUserId ?
                    (<span>{oppositeUserData?.name}:&nbsp;</span>) : (<span className='font-[600] text-md'>You:&nbsp;</span>)}
                  <span ref={messageRef} className=''>{latestMessageData?.content}</span>

                </div>)}

            </div>
          </div>
        </Link>
      )
    } else {
      // unread design
      return (
        <Link className='overflow-x-hidden lg:w-full font-[600] w-[1000px] p-4 flex flex-row card shadow-2xl bg-base-200 justify-between h-32 items-center' to={`/chat/${props.id}`} >
          <div className='card-body p-0 h-full flex flex-row gap-4 items-center'>
            {oppositeUserData.pfp_link !== null ? (<img src={oppositeUserData.pfp_link!} className="w-20 sm:w-16  rounded-full" />
            ) : (<div className='sm:text-[64px] text-[80px]'><AccountCircleIcon fontSize='inherit' /></div>)}
            <div className='gap-2 flex flex-col'>
              <h1 className='font-bold text-2xl sm:text-xl'>{oppositeUserData?.name}</h1>
              {latestMessageData === null ? (<p>Click here to begin the conversation!</p>)
                : (<div className='flex flex-row items-center'>
                  {latestMessageData?.user_id === oppositeUserId ?
                    (<span>{oppositeUserData?.name}:&nbsp;</span>) : (<span className='font-[600] text-md'>You:&nbsp;</span>)}
                  <span ref={messageRef} className=''>{latestMessageData?.content}</span>

                </div>)}
            </div>
          </div>
          <div className='w-6 h-6 sm:w-4 sm:h-4 bg-slate-300 rounded-xl' >

          </div>
        </Link>
      )
    }




  }

}

export default ChatCard
