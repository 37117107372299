import { zodResolver } from '@hookform/resolvers/zod'
import { UUID } from 'crypto'
import React from 'react'
import { CountryDropdown } from 'react-country-region-selector'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { supabase } from '../utils/supabase'

interface Props {
  id: UUID
}

const ConfigSchema = z.object({
  gender: z.string().optional().nullable(),
  age: z.number().min(18).max(99).optional().nullable(),
  country: z.string().optional().nullable()
})

type ConfigSchemaType = z.infer<typeof ConfigSchema>;

const Config = (props: Props) => {

  const {
    unregister,
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<ConfigSchemaType>({
    resolver: zodResolver(ConfigSchema),
    defaultValues: async () => {
      const { data, error } = await supabase.from("user_config").select().eq("id", props.id).maybeSingle()
      if (data !== null) {
        return data
      } else {
        return {
          gender: null,
          age: null,
          country: null
        }
      }
    }

  });
  if (errors) {
    console.log(errors)
  }
  const onSubmit: SubmitHandler<ConfigSchemaType> = async (submittedData, e) => {
    const { error } = await supabase.from("user_config").upsert({ ...submittedData, id: props.id })
    if (error) {
      console.log(error)
    }
    e?.preventDefault()
  };

  async function deleteConfig() {
    const { error } = await supabase.from("user_config").delete().eq('id', props.id)
    if (error) {
      console.log(error)
    }
    window.location.reload()
  }

  return (
    <div className='flex items-center flex-col gap-4 justify-center bg-base-200 xs:max-w-80 max-w-96 rounded-3xl p-8 self-center'>
      <h1 className=' font-[600] text-lg'>Preferences</h1>
      <form className='items-center flex flex-col w-full gap-4' onSubmit={handleSubmit(onSubmit)}>
        <div className='w-full flex flex-col gap-2'>
          <h3>Country (Optional)</h3>
          <Controller
            name="country"
            render={({ field: { name, onChange, value } }) => (
              <CountryDropdown
                defaultOptionLabel="Select Country"
                name={name}
                value={value}
                onChange={onChange}
                // @ts-ignore NOTE: this works anyways
                className='select w-full select-bordered'
              />
            )}
            control={control}
          />
          <label className='text-sm opacity-70' htmlFor='country'>* Select the country you want to see users from.</label>
          {errors.country && <span className='text-red-700'>{errors.country.message}</span>}

        </div>
        <div className='w-full flex flex-col gap-2'>
          <h3>Maximum Age (Optional)</h3>
          <input {...register("age", { valueAsNumber: true })} type="number" min={18} max={99} className="input input-bordered text-sm" />
          <label className='text-sm opacity-70' htmlFor='country'>* Select the max age of users you want to see.</label>
          {errors.age && <span className='text-red-700'>{errors.age.message}</span>}

        </div>
        <div className='w-full flex flex-col gap-2'>
          <label htmlFor='gender'>Gender (Optional)</label>
          <select {...register("gender")} className='select select-bordered'>
            <option disabled selected ></option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          {errors.gender && <span className='text-red-700'>{errors.gender.message}</span>}
          <label className='text-sm opacity-70' htmlFor='country'>* Select the gender of users you want to see.</label>
        </div>

        <button className='btn btn-success w-full' type='submit'>Submit Preferences</button>
        <button className='btn btn-error w-full' onClick={() => deleteConfig()}>Delete Preferences</button>
      </form>
    </div>
  )
}

export default Config
